<template>
  <div class="reset-password-page">
    <n-loader :loading="$var('load')" />
    <div class="container">
      <div class="wrap-title">
        <div class="title">Смена пароля</div>
      </div>
     <div class="wrap">
       <div class="inputs">
         <n-input title="Введите старый пароль" :danger="$var('m1')" v-bind="$form.input('oldPassword')"></n-input>
         <n-input title="Введите новый пароль" :danger="$var('m2')" v-bind="$form.input('newPassword')"></n-input>
         <n-input title="Повторите новый пароль" :danger="$var('m3')" v-bind="$form.input('confirmPassword')"></n-input>
       </div>
       <div class="buttons">
         <n-button @click="resetPass">Сохранить</n-button>
       </div>
     </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'PageResetPassword',
  data() {
    return {
      
    }
  },
  created() {
    this.$form.init({
      oldPassword: '',
      newPassword: '',
      confirmPassword: '',
    })
    this.$form.rules({
      oldPassword: [ 'required', ],
      newPassword: [ 'required', ],
      confirmPassword: [ 'required', ],
    })
    this.$var('m1', '')
    this.$var('m2', '')
    this.$var('m3', '')
  },
  methods: {
    resetPass() {
      if (this.$form.check()) {
        $api.user.editPass($app.auth.user().id, this.$form.get()).then(() => {
          $app.auth.logout()
        }).catch((error) => {
          const messages = error.response.data
          this.$var('m1', $n.getMessage(messages, 'oldPassword'))
          this.$var('m2', $n.getMessage(messages, 'newPassword'))
          this.$var('m3', $n.getMessage(messages, 'confirmPassword'))
        })
      } else {
        if (!this.$form.get('oldPassword')) {
          this.$var('m1', 'Поле обязательно для заполнения')
        }
        if (!this.$form.get('newPassword')) {
          this.$var('m2', 'Поле обязательно для заполнения')
        }
        if (!this.$form.get('confirmPassword')) {
          this.$var('m3', 'Поле обязательно для заполнения')
        }
      }
    },
  },
}
</script>

<style scoped lang="scss">
.reset-password-page {
  padding-top: calc(env(safe-area-inset-top) + 40px);

  .wrap-title{
    text-align: center;
    position: fixed;
    background-color: #F5F5FA;
    left: 0;
    right: 0;
    top: calc(env(safe-area-inset-top) +  100px);
  }

  .wrap{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    min-height: 55vh;
  }

  .title {
    font-weight: 700;
    color: #000000;
    text-align: center;
  }
  .inputs {
    flex: 10;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    ::v-deep label {
      color: #959494;
      font-weight: 700;
      font-size: 14px;
    }
    ::v-deep .n-content {
      height: 40px;
      border: none !important;
      background: #F5F5FA !important;
      border-radius: 16px !important;
      box-shadow: inset -2.5px -2.5px 5px #FAFBFF, inset 2.5px 2.5px 5px #CBCBE0 !important;
    }
  }

  .buttons {
    flex: 0;
    width: 100%;

    .n-button {
      color: #FFFFFF;
      width: 100%;
      border: 3px solid var(--primary);
      border-radius: 24px;
      background-color: var(--primary);

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
</style>
